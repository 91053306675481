jQuery(function($) {

  /* initialize start ticker */

  var startTicker = new Swiper ('.startTickerContainer', {
    effect: 'fade',
    fade: { crossFade: true },
    spaceBetween: 30,
    autoHeight: true, //enable auto height
    autoplay: {
      delay: 9000,
    }
  })

  /* initialize start slider */

  var startSlider = new Swiper ('.startSliderContainer', {
    loop: false,
    autoplay: {
      delay: 9000,
    }
  })

  /* initialize reference slider */

  var referenceSlider = new Swiper ('.lawAreaContainer', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.lawAreaPagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.lawAreaNext',
      prevEl: '.lawAreaPrev',
    },
    breakpoints: {
      // when window width is <= 320px
      649: {
        slidesPerView: 1
      },
      1023: {
        slidesPerView: 2
      },
    }
  })

  /* label animation */

  $(".fieldWrapper input[type='text'], .fieldWrapper input[type='email'], .fieldWrapper textarea").on('blur', function(){
    if( !$(this).val() ) {
      $(this).parent().parent().removeClass('active');
    }
  }).on('focus', function(){
    $(this).parent().parent().addClass('active');
  });

  /* accordion */

    if ($('.accordion').length) {

      $(".accordionContent").hide().first().show();
      $(".accordionTitle").first().toggleClass('open');

      toggleAccordion();

    }

    function toggleAccordion(){

      $('.accordionTitle').on('click', function(){
        $(this).toggleClass('open');
        $(this).next('.accordionContent').slideToggle();
      });

    }

  /* enable select2 for select fields */

  $('.selectTwo').select2({
    minimumResultsForSearch: Infinity,
    width: "style"
  });

  /* animations */

  if ($('.process').length) {

    $('.process .title, .process .content').addClass('hide');

    var processTitle = new Hunt(document.querySelectorAll('.process .title'), {
      enter: function(el) {
        el.classList.remove('hide');
        el.classList.add('fadeInLeft');
      },
      offset: -200
    })

    var processContent = new Hunt(document.querySelectorAll('.process .content'), {
      enter: function(el) {
        el.classList.remove('hide');
        el.classList.add('fadeInUp');
      },
      offset: -200
    })

  }


  if ($('.contentElement').length) {

    var element = new Hunt($('.contentElement h2').addClass('hide'), {
      enter: function(el) {
        el.classList.remove('hide');
        el.classList.add('fadeInLeftShort');
      },
      offset: 0
    })

    var element = new Hunt($('.contentElement p').addClass('hide'), {
      enter: function(el) {
        el.classList.remove('hide');
        el.classList.add('fadeInUp');
      },
      offset: 0
    })


  }

});
